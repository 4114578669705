@import 'colors';
@import 'typography';

.about-header {
    height: 3rem;
    .header {
        background: $red;
    }
}

.about-hero {
	display: flex;
    color: white;
    background: $black;
    // border-bottom: 1px solid rgba(255,255,255,0.1);
    .left-col {
        display: flex;
        align-items: flex-end;

        width: 60%;

        // background: white;

        p {
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin-bottom: 0rem;
            opacity: 0.66;
        }
    }	

	.right-col {
        // background: white;
		width: 66%;

        padding-left: 0rem;

        div {
            padding-left: 4rem;
            border-left: 1px solid rgba(255,255,255,0.1);
        }
        .photo-portrait {
            display: block;
            position: relative;

            width: 100%;
            height: 100%;

            // background-image: url("/img/about/1.jpg");
            background-size: cover; 
        }    
        
	}

}

@media screen and (max-width: 960px) {
    .about-hero {
        flex-wrap: wrap;

        .left-col {
            order: 2;
            width: 100%;
            .contacts {
                flex-wrap: wrap;           
                .contact {
                    width: 100%;
                    margin-bottom: 1rem;

                    &:last-of-type {
                        margin: 0;
                    } 
                }
            }
        }

        .right-col {
            order: 1;
            width: 100%;

            border-bottom: 1px solid rgba(255,255,255,0.1);
            div {
                border-left: 0;
            }
        }
    }                             
}

.process {
    border-bottom: 0;
}

.photo-grid {
    padding-top: 0;

    border-bottom: 1px solid rgba(255,255,255,0.1);
    background: #1F1D1D;
    text-align: center;
    div {
        display: inline-block;
        position: relative;
        max-width: 1600px;
        img {
            width: 100%;
            
            opacity: 0;
            transition: all 0.66s ease;

            &:nth-child(2) {
                position: absolute;
                top: 0;
                left: 0;
            }

            &:nth-child(3) {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .active-slide {
            opacity: 1;
        }
    } 
}

.quoute {
    text-align: center;

    // background: #2a263b;
}

.myfeatures {
	display: flex;
    color: white;
    background: #1F1D1D;
    // border-bottom: 1px solid rgba(255,255,255,0.1);
    .left-col, .right-col {
        background: #1F1D1D;
        // padding-right: 0;
    }
    .photo-portrait {

            display: block;
            position: relative;

            width: 100%;
            height: 100%;

            padding-left: 4rem;
            border-left:  1px solid rgba(255,255,255,0.1);

            // background-image: url("/img/about/1.jpg");
            background-size: cover;

            .photo-slider {
                position: relative;
                overflow: hidden;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                transition:all 0.3s ease;
                opacity: 0;
                width: 100%;

                &:nth-child(1) {
                    position: relative;
                }
            } 


            .active-photo {
                opacity: 1;
            }    
        } 

    
    ul {
        counter-reset: listStyle;
        
        p { position: relative; top: 2px; }
        
        li {
            counter-increment: listStyle;
            padding: 1rem 0;

            

            border-top:  1px solid rgba(255,255,255,0.1);
            // border-bottom: 1px solid rgba(0,0,0,0.1);
            transition:all 0.3s ease;

            &:first-of-type {
                border-top: 0;
            }

            &:hover {
                cursor: pointer;
            }

            p {
                opacity: 0.5;
                transition:all 0.3s ease;
            }
        }

        .active {
            p {
                opacity: 1;
            }
            
        }
    }

}

@media screen and (max-width: 1200px) {
    .myfeatures  {
        flex-wrap: wrap;

        .left-col {
            width: 100%;
            .contacts {
                flex-wrap: wrap;           
                .contact {
                    width: 100%;
                    margin-bottom: 1rem;

                    &:last-of-type {
                        margin: 0;
                    } 
                }
            }
        }

        .right-col {
            width: 100%;
            border-top:  1px solid rgba(255,255,255,0.1);
        }

        .photo-portrait {
            border-left: 0;
            
            padding-left: 0;
        }
    }                             
}



