@font-face {
	font-family: "Druk Wide Cyr Super";
	src : url("../fonts/Druk Wide Cyr Super.otf");
}

@font-face {
	font-family: "Mabry Pro";
	src : url("../fonts/Mabry Pro.otf");
}

// Font weights
$extra-bold: 800;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;
// Fonts
$drukwide: "Druk Wide Cyr Super";
$mabry: "Mabry Pro";

$sans: 'Mabry Pro', sans-serif;

body {
	font-family: $mabry;
}

h1, %h1 {
	font-family: $drukwide;
	font-size: 3rem;
    text-transform: uppercase;
	font-weight: $light;
    line-height: 1;
	letter-spacing: 0.5px;
	margin-bottom: 2.5rem;
}

h2, %h2 {
	font-family: $drukwide;
    text-transform: uppercase;
	font-size: 2.5rem;
	font-weight: $medium;
	line-height: 1;

	margin-bottom: 2rem;
}

h3, %h3 {
    font-family: $drukwide;
    text-transform: uppercase;
	font-size: 1.5rem;
	letter-spacing: 0.5px;

    margin-bottom: 1rem;
}

h4, %h4 {

	font-size: 1.1rem;
	letter-spacing: 0.5px;
}

h5, %h5 {
	font-size: 0.75rem;
	text-transform: uppercase;
}

p, .p {
	font-size: 1rem;
    line-height: 1.25;
}

strong {
	font-weight: $medium;
}