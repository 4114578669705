@import 'colors';


.button-group {
	display: flex;
	margin-bottom: 20px;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

	.button {
		display: inline-block;
        position: relative;
        // font-family: $drukwide;
        text-transform: uppercase;
        font-size: 1rem;

		padding: 1rem 1rem;

		background: $black;
		border: none;
		color: $white;
		cursor: pointer;
		width: 20%;

        transition:all 0.3s ease;

	}

	.button:hover {
		background-color: $white;
		color: $black;
	}

	.button:active,

	.button.is-checked {
		background-color: $red;
        color: white;
	}

}


@media screen and (max-width: 960px) {
    .button-group {
        display: flex;
        flex-wrap: wrap;

        .button {
            width: 50%;
        }
    }    


}



/* ---- isotope ---- */

.grid {
	// border: 1px solid #333;
}

/* clear fix */
.grid:after {
	content: '';
	display: block;
	clear: both;
}

/* ---- .element-item ---- */

.element-item {
    display: flex;
	position: relative;
	float: left;
	width: 25%;
	height: 12rem;
	padding: 10px;
    overflow: hidden;
    background: $black;

    &:hover {
        cursor: pointer;         
        .work-cover {
            transform: scale(1.3);
            opacity: 0.3;
        }

        .name {
            opacity: 1;
            transform: translateY(0px); 
        }
    }

    .name {
        position: relative;
        margin: auto;
        text-align: center;
        z-index: 10;     
        color: white;
        opacity: 0;
        font-size: 1rem;
        transform: translateY(-10px);  

        transition:all 0.3s ease;        
    }

    .work-cover {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

        z-index: 0;

        transition:all 0.3s ease;
    }
}

.element-item > * {
	margin: 0;
	padding: 0;
}

@media screen and (max-width: 960px) {
    .element-item {
        width: 50%;
        height: 16rem;

        .work-cover {
            // transform: scale(1.3);
            opacity: 0.3;
        }

        .name {
            opacity: 1;
            transform: translateY(0px); 
        }
    }    
}

@media screen and (min-width: 1920px) {
    .element-item {
        // height: 24rem;
    }    
}

@media screen and (min-width: 2440px) {
    .element-item {
        height: 20rem;
    }    
}

@media screen and (max-width: 640px) {
    .element-item {
        width: 100%;
        // height: 24rem;;

        .work-cover {
            // transform: scale(1.3);
            opacity: 0.3;
        }

        .name {
            opacity: 1;
            transform: translateY(0px); 
        }
    }    
}

