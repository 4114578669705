@import 'colors';
@import 'typography';

.menu {
    display: flex;
    position: fixed;

    top: 0;
    left: 0;

    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100vh;

    z-index: 10;

    pointer-events: none;
    
    .content {
        position: relative;
        z-index: 10;

        a {
            display: block;
            text-align: center;
            font-family: $drukwide;
            text-transform: uppercase;
            font-size: 2.5rem;
            font-weight: $medium;
            line-height: 1;
            margin-bottom: 2rem;
            color: $red;

            transform: translate(0px,50px);
            opacity: 0;

            transition:all 0.3s ease;

            &:hover {
                color: $white;
            } 
            
            &:last-of-type {
                margin: 0;
            }

        }
    }   

    .top {
        display: block;
        position: absolute;
        top: -50%;
        left: 0;

        width: 100%;
        height: 50%;

        background: $black;

        z-index: 5;

        transition:all 0.3s ease;
    }

    .bottom {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;

        width: 100%;
        height: 50%;

        background: #1C1919;

        z-index: 5;

        transition:all 0.3s ease;
    }
}

.open {
    pointer-events: auto;

    .content {
        position: relative;
        z-index: 10;

        a {
            transform: translate(0px,0px);
            opacity: 1;
        }
    }       
    .top {
        top: 0%;
    }

    .bottom {
        top: 50%;
    }   
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    padding: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    height: 2rem;
    width: 100%;
    // background: rgba(0,0,0,0.1);
    top: 0;
    left: 0;

    z-index: 10;

    .left { display: flex; height: 1rem }

    transition:all 0.3s ease;

    .logo-small {
        height: 1rem;

        img { height: 100%; }

        margin-right: 1rem;
    }

    .languages {
        position: relative;
        top: -0.2rem;
        width: 2rem;
        height: 1.5rem;

        border-radius: 0.25rem;

        background: rgba(0,0,0,0.1);

        overflow: hidden;

        .button {
            display: flex;
            position: absolute;

            top: 0;
            left: 0;


            width: 100%;
            height: 100%;

            transition:all 0.3s ease;

            img { margin: auto; width: 66%; height: 66%;}
        }

        .button-active {
            display: flex;
            position: absolute;

            top: -100%;
            left: 0;

            width: 100%;
            height: 100%;

            transition:all 0.3s ease;

            img { margin: auto; width: 66%; height: 66%;}
        }

        &:hover {
            background: rgba(0,0,0,0.5);
            cursor: pointer;

            transition:all 0.3s ease;

            .button {
                top: 100%;
            }

            .button-active {
                top: 0;
            }
        }
    }

    .menu-button {
        dislay: block;
        position: relative;
        width: 2rem;
        height: 1rem;
        z-index: 11;
        transform-origin: center;

        transition:all 0.1s ease;

        &:hover {
            // transform: scale(0.75);
            // opacity: 0.5;
            cursor: pointer;
        }
        &:active {
            // opacity: 1;
        }

        hr {
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            height: 0.34rem;
            margin: 0px;
            border: 0;
            background: white;
            transition:all 0.3s ease;

            &:last-of-type {
                top: 0.66rem;
                margin: 0;
            }
        }
    } 

    .menu-button-clicked {
        top: 5px;
        hr {
            transform: rotate(45deg);

            &:last-of-type {
                transform: rotate(-45deg);
                top: 0;
                margin: 0;
            }
        }

    }
}

.header-red {
    background: $red;
} 

#object {
    position: fixed;
    left: calc(50%-150px);
    top: 25%;
    width: 300px;

    z-index: 11;

    transition:all 0.1s ease;
}

.footer {
    display: flex;
    background: black;
    color: gray;
    justify-content: space-between;
    text-transform: uppercase;

    .oz-copyright {
       text-align: right;
    }
}