html, body {
	// background-color: $white;
	color: $white;
	// font-family: $sans;
	font-size: 16px;
	// font-weight: $light;
	height: 100%;
	line-height: 1.5;
	position: relative;
}

*, *::after, *::before {
	box-sizing: border-box;
    outline: none;
}

a, a:hover {
	color: inherit;
	text-decoration: none;
}

img {
	max-width: 100%;
}

.lock-site {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.red-text {
    color: $red;
}

.section {
    display: block;
    position: relative;
    padding: 4rem;
    width: 100%;

    color: white;
    background: $black;

    z-index: 0;
}

.video-hero {
                 
  height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
  
  /* Center the video */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  z-index: -1;
}

.hero-works {
    .video-hero {
        top: 25%;
    }
}

.notification {
    display: block;
    position: fixed;

    top: 8rem;
    left: 5%;

    width: 90%;
    padding: 1rem;

    text-align: center;

    background: $red;

    z-index: 100;

    opacity: 0;

    transition:all 0.3s ease;
}

.active-notification {
    top: 6rem;
    opacity: 1;
}