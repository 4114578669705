@import 'colors';
@import 'typography';



.hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 4rem;
    min-height: 100%;
    min-width: 100%;
    background: $black;
    overflow: hidden;

    .content {
        text-align: center;
        z-index: 10;
        color: white;
        .logo-big {
            display: inline-block;
            width: 320px;
            margin-bottom: 2rem;
        }

        .button {
            display: inline-block;
            position: relative;
            background: transparent;
            border-bottom: 2px solid white;
            width: 10rem;
            height: 3rem;
            color: white;

            line-height: 3rem;

            overflow: hidden;

            .button-text {
                display: block;
                position: absolute;

                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                transition:all 0.3s ease;
            }

            .button-symbol {
                display: block;
                position: absolute;

                top: -100%;
                left: 0;

                width: 100%;
                height: 100%;

                transition:all 0.3s ease;
            }

            &:hover {
                background: $black;
                border-bottom: 2px solid $red;
                cursor: pointer;

                transition:all 0.3s ease;

                .button-text {
                    top: 100%;
                }

                .button-symbol {
                    top: 0;
                }
            }
        }
    }
}

.hero-works {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 4rem;
    min-height: 50%;
    min-width: 100%;
    color: $white;
    background: $black;

    overflow: hidden;

    .content {
        text-align: center;
    }
}

.aboutme {
    color: white;
    background: $black;
    // border-bottom: 1px solid rgba(255,255,255,0.1);
}

.specialization {
    display:flex;
    justify-content: space-between;
    counter-reset: listStyle;

    li {
        width: 30%;
        counter-increment: listStyle;

        margin-bottom: 1rem;

        &:before {
            content: counter(listStyle, decimal);
            display: block;
            width: 2rem;
            height: 2rem;
            font-weight: 600;
            font-size: 1rem;
            text-align: center;
            line-height: 1.75rem;
            margin-bottom: 1rem;
            color: $red;
            border: 2px solid $red;
            border-radius: 3rem;

        }
    }  
}

@media screen and (max-width: 640px) {
    .specialization {
        flex-wrap:wrap;

        li {
            width: 100%;
            margin-bottom: 2rem;

            &:last-of-type {
                margin: 0;
            } 
        }
    }
}

.myworks {
    color: white;
    background: #1F1D1D;
    // border-bottom: 1px solid rgba(255,255,255,0.1);
}
.works {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .work {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 49%;
        margin-bottom: 2%;
        height: 20rem;
        padding: 2rem;
        background: $red;
        overflow: hidden;
        filter: saturate(0);

        transition:all 0.1s ease;

        &:before {
            content: " ";
            display: block;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: 100%;
            background: $black;
            opacity: 0.75;
            z-index: 1;

            transition:all 0.3s ease;
        }

        &:hover {
            filter: saturate(1);
            cursor: pointer;
            .cover {
                transform: scale(1);
            }

            .label {
                transform: scale(1.15);
                background: $red;
            }

            &:before {
                top: 0%;
            }
        }
        
        .label {
            position: relative;
            z-index: 1;
            transition:all 0.3s ease;
            padding: 1rem;    
            background: $black;

            h3 {
                color: $white;
                margin: 0;
            }

        }
        .cover {
            display: block;
            position: absolute;
            width: 100%;
            min-height: 100%;
            top: 0;
            left: 0;
            z-index: 0;
            transform: scale(1.4);
            transition:all 0.3s ease;
        }
    }

}

@media screen and (max-width: 1200px) {
    .works {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        
        .work {
            width: 49%;
            background-size: cover;

            &:nth-child(1) {
                background-image: url("/img/works/car.png");
            }
            &:nth-child(2) {
                background-image: url("/img/works/boat.png");
            }
            &:nth-child(3) {
                background-image: url("/img/works/report.png");
            }
            &:nth-child(4) {
                background-image: url("/img/works/commercial.png");
            }
            &:nth-child(5) {
                background-image: url("/img/works/review.png");
            }
            &:nth-child(6) {
                background-image: url("/img/works/music.png");
            }  

            .cover {
                display: none;
            }                  
        }
    }                
}


@media screen and (max-width: 960px) {
    .works {
        
        .work {
            width: 100%;
        }
    }
}    

@media screen and (min-width: 1920px) {
    .works {
        
        .work {
            height: 24rem;
        }
    }
}    

.clients {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    a {
        width: 20%;
        height: 8rem;
    }
    .client {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 1rem;
        // border: 1px solid rgba(0,0,0,0.1);
        filter: saturate(0);
        opacity: 0.5;
        transition:all 0.3s ease;

        &:hover {
            // filter: saturate(1);
            opacity: 1
        }
        
        img {
            margin: auto;
            width: 66%;
        }
    }
}

@media screen and (max-width: 960px) {
    .clients {
        a {
            width: 50%;
        }
    }              
}

.contactme {
    display: flex;
    position: relative;

    .left-col {
        display: flex;
        flex-direction: column;
        width: 50%;
        background: $black;
        color: $white;
        justify-content: space-between;

        p {
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin-bottom: 2rem;
            opacity: 0.66;
        }

        .contacts {
            display: flex;
            justify-content: space-between;

            .contact {
                width: calc(33.33%-0.66rem);
                border: 1px solid rgba(255,255,255,0.0);
                padding: 0rem;
                font-family: $drukwide;
                text-transform: uppercase;
                font-size: 0.75rem;
                transition:all 0.3s ease;

                &:hover {
                    
                    cursor: pointer;
                     img {
                        opacity: 1;
                        transition:all 0.3s ease;
                    }
                }

                img {
                    display: block;
                    height: 2.5rem;
                    width: 2.5rem;
                    margin-bottom: 1rem;
                    opacity: 0.2;
                }

                a {
                    // color: $black;
                }
            }
        }
    }

    .right-col {
        width: 50%;
        // background-image: url("/img/about/2.jpg");
        background: $black;
        border-left: 1px solid rgba(255,255,255,0.1);

        .form {
            padding: 2rem;
            background: $white;

            input,textarea {
                display: block;
                width: 100%;
                
                padding: 1rem;
                font-size: 1rem;
                font-family: $mabry;

                border: 0;
                background: white;

                margin-bottom: 1rem;

                resize: vertical;
            }

            .submit {
                display: block;
                width: 100%;

                // height: 2rem;
                font-size: 1rem;
                padding: 1rem;
                border: 0;
                color: white;
                background: $red;

                font-family: $drukwide;
                text-transform: uppercase;

                transition:all 0.3s ease;

                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                &:hover {
                    background: $black;
                    cursor: pointer;
                }     

            }       
        }
    }
    
}

@media screen and (max-width: 960px) {
    .contactme {
        flex-wrap: wrap;

        .left-col {
            width: 100%;
        }

        .right-col {
            width: 100%;

            border-left: 0;
            border-top: 1px solid rgba(255,255,255,0.1)
        }
    }                             
}

@media screen and (max-width: 640px) {
    .contactme {
        .left-col {
            .contacts {
                flex-wrap: wrap;           
                .contact {
                    width: 100%;
                    margin-bottom: 1rem;

                    &:last-of-type {
                        margin: 0;
                    } 
                }
            }
        }
    }    
}