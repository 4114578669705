@import 'colors';
@import 'typography';

.wrapper {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 100;
}

.scene {
    position: relative;
    top: calc(50%-3rem);
    left: calc(50%-10rem);
    width: 20rem;
    height: 6rem;
    perspective: 600px;

    animation-duration: 2s;
    animation-name: fadein;
    animation-iteration-count:1;
    animation-timing-function: cubic-bezier(0.48, 0.28, 0.05, 0.95);
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;          
    }
}

.cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    animation-duration: 6s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.48, 0.28, 0.05, 0.95);
}

@keyframes slidein {
    0% {
        transform: rotateY(0deg)
    }

    25% {
        transform: rotateX( -90deg);
    }

    50% {
        transform: rotateX(-180deg);
    }

    75% {
        transform: rotatex(-270deg);           
    }

    100% {
        transform: rotatex(-360deg);           
    }
}

.cube__face {
    position: absolute;
    width: 20rem;
    height: 6rem;
    font-family: $drukwide;
    text-transform: uppercase;
    font-size: 1.0rem;
    font-weight: $medium;
    text-align: center;
    line-height: 5.7rem;
}

.cube__face--front  {  
    color: #231f20;
    background: #c5c4c4; 
    transform: rotateY(0deg) translateZ(3rem); 
}

.cube__face--back   { 
    color: #d15852; 
    background: #211f1f; 
    transform: rotateY(180deg) rotateZ(180deg) translateZ(3rem); 
}
.cube__face--top    { 
    color: #231f20; 
    background: #d15852;
    transform: rotateX( 90deg) translateZ(3rem); 
}
.cube__face--bottom { 
    color: #231f20; 
    background: #f1ede6; 
    transform: rotateX(-90deg) translateZ(3rem); 
}